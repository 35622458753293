import { ListGuesser, FieldGuesser } from '@api-platform/admin';
import { Fragment } from 'react';
import { useListContext, BulkDeleteButton, BulkExportButton } from 'react-admin';

const PostBulkActionButtons = ({ basePath, selectedIds }) => {
    const { data } = useListContext();
    // we can't delete non selectable area types
    const canDelete = undefined != selectedIds.find(id => !data[id].selectable);

    return (
        <Fragment>
            <BulkExportButton />
            <BulkDeleteButton basePath={basePath} disabled={canDelete} />
        </Fragment>
    );
}

export default props => (
    <ListGuesser {...props} bulkActionButtons={<PostBulkActionButtons />}>
        <FieldGuesser source={"name"} />
        <FieldGuesser source={"selectable"} />
    </ListGuesser>
);
