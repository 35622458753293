import React from "react";
import {
    ReferenceField,
    TextField,
    useRecordContext,
} from 'react-admin';

export const InnerRatio = props => {
    const record = useRecordContext();
    let ratio = record.record.ratio.indexOf("inner_ratios") !== -1
    return ratio ?
        <ReferenceField {...props} reference="inner_ratios" source={"ratio"}>
            <TextField source="id"/>
        </ReferenceField> : null
};

export const DynamicRatio = props => {
    const record = useRecordContext();
    let ratio = record.record.ratio.indexOf("dynamic_ratios") !== -1
    return ratio ?
        <ReferenceField {...props} reference="dynamic_ratios" source={"ratio"}>
            <TextField source="id"/>
        </ReferenceField> : null
};

export const FixedRatio = props => {
    const record = useRecordContext();
    let ratio = record.record.ratio.indexOf("fixed_ratios") !== -1
    return ratio ?
        <ReferenceField {...props} reference="fixed_ratios" source={"ratio"}>
            <TextField source="id"/>

        </ReferenceField> : null
};
