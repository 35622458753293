import { ShowGuesser, FieldGuesser } from "@api-platform/admin";
import { TabbedShowLayout, Tab , useShowController } from 'react-admin'
import Mapbox from '../../../component/mapbox/Mapbox';

export default props => {
    const {record} = useShowController(props);
    
    return (
    <ShowGuesser {...props}>
        <TabbedShowLayout>
            <Tab label="summary">
                <FieldGuesser source={"country"} addLabel={true} />
                <FieldGuesser source={"country_code"} addLabel={true} />
                <FieldGuesser source={"title"} addLabel={true} />
                <FieldGuesser source={"is_weather_enabled"} addLabel={true} />
                <FieldGuesser source={"created_at"} addLabel={true} />
            </Tab>
            <Tab label="map">
                {record && (<Mapbox geojson={record.geo_json}/>)}
            </Tab>
        </TabbedShowLayout>


    </ShowGuesser>
    );
};
