import { Auth } from "aws-amplify";

export const checkAuth = () =>
    Promise.resolve().then(() => {
        if (localStorage.getItem("token")) return Promise.resolve();

        return Auth.currentSession()
            .then((session) =>
                localStorage.setItem("token", session.getAccessToken().getJwtToken())
            )
            .catch(() =>
                Promise.resolve()
                    .then(() => localStorage.removeItem("token"))
                    .then(() => Promise.reject())
            );
    });

export const logout = () =>
    Promise.resolve()
        .then(() =>
            Auth.currentSession()
                .then(() => true)
                .catch(() => false)
        )
        .then((authenticated) => (authenticated ? Auth.signOut() : null))
        .then(() => localStorage.removeItem("token"));

export default {
    // authentication
    login: () =>
        Promise.reject("Unrecheable code (authentication from checkAuth"),
    checkError: (error) =>
        Promise.resolve(error).then((error) => console.error(error)),
    checkAuth: checkAuth,
    logout: logout,
    getIdentity: () => Promise.reject(),
    // authorization
    getPermissions: (params) => Promise.reject(),
};
