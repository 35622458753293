import React from "react";
import {
    hydraDataProvider as baseHydraDataProvider,
    fetchHydra as baseFetchHydra,
} from "@api-platform/admin";
import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation";
import {checkAuth, logout} from './authProvider';
import {Redirect, Route} from "react-router-dom";

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;

const getHeaders = () => localStorage.getItem('token') ? {
    Authorization: `Bearer ${localStorage.getItem('token')}`,
} : {};


const fetchHydra = async (url, options = {}) => {
    // we wait for the auth if needed
    await checkAuth().catch(error => error);

    return baseFetchHydra(url, {...options, headers: getHeaders()});
}
const RedirectToLogin = () => {
    // const introspect = useIntrospection();

    if (localStorage.getItem('token')) {
        // introspect();
        return <></>;
    }
    return <Redirect to="/login"/>;
};


const apiDocumentationParser = async (entrypoint) => {
    // we wait for the auth if needed
    await checkAuth().catch(error => error);

    try {
        const {api} = await parseHydraDocumentation(entrypoint, {headers: getHeaders()});
        return {api};
    } catch (result) {
        console.log(result.status);

        if (result.status === 401) {
            await logout();

            return {
                api: result.api,
                customRoutes: [
                    <Route path="/" component={RedirectToLogin}/>
                ],
            };
        }

        throw result;
    }
};


const customDataProvider = abstractDataProvider => ({
    ...abstractDataProvider,
    create: (resource, params) => {

        return abstractDataProvider.create(resource, params);
    },
    update: (resource, params) => {
       console.debug(params);

        return abstractDataProvider.update(resource, params);
    },
});

export default customDataProvider(
  baseHydraDataProvider(entrypoint, fetchHydra, apiDocumentationParser)
);
