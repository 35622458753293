import React  from "react";
import {
    NumberInput,
    TextInput,
    SelectInput
} from 'react-admin';

const RatioTypes = [
    {id : "dynamic", name:"DynamicRatio" },
    {id : "fixed", name:"FixedRatio" },
    {id : "inner", name:"InnerRatio" },
]

const DisplayRatios = function ()
{
    const [dynamic_ratio, setDynamicRatio] = React.useState(true)
    const [fixed_ratio, setFixedRatio] = React.useState(false)
    const [inner_ratio, setInnerRatio] = React.useState(false)
    const handleChange = function(event){
        setDynamicRatio(false)
        setFixedRatio(false)
        setInnerRatio(false)
        switch (event.target.value)
        {
            case "dynamic": setDynamicRatio(true); break;
            case "fixed": setFixedRatio(true); break;
            case "inner": setInnerRatio(true); break;

        }
    }

    const DisplayDynamicRatio = function (){
        return dynamic_ratio ?
            (<div>
                <NumberInput source={"ratio.min"}/>
                <NumberInput source={"ratio.max"}/>
                <NumberInput source={"ratio.step_min"}/>
                <NumberInput source={"ratio.step_max"}/>
                <NumberInput source={"ratio.percent"}/>
            </div>)
            : null;
    }
    const DisplayFixedRatio = function (){
        return fixed_ratio ?
            (<div>
                <NumberInput source={"ratio.min"}/>
                <NumberInput source={"ratio.max"}/>
            </div>)
            : null;
    }
    const DisplayInnerRatio = function (){
        return inner_ratio ?
            (<div>
                <NumberInput source={"ratio.weight"}/>
                <NumberInput source={"ratio.power_min"}/>
                <NumberInput source={"ratio.power_max"}/>
                <NumberInput source={"ratio.power_step"}/>
            </div>)
            : null;
    }
    return(<div>
            <SelectInput
                         source={"ratio.type"}
                         choices={RatioTypes}
                         label={"type"}
                         onChange={handleChange}
            />
            <DisplayDynamicRatio/>
            <DisplayFixedRatio/>
            <DisplayInnerRatio/>

        </div>
    );
}
export default DisplayRatios;