import React from "react";
import { Login, Button } from "react-admin";
import Amplify, { Auth } from "aws-amplify";

const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const redirectUrl = isLocalhost
    ? "http://localhost:3000/"
    : "https://forge.hootside.com/";

Amplify.configure({
    userPoolId: "eu-west-3_tvYaURkLu",
    userPoolWebClientId: "2ppkv4ep7a5jdepfmh69igc9uh",
    region: "eu-west-3",
    mandatorySignIn: true,
    oauth: {
        domain: "astvv.auth.eu-west-3.amazoncognito.com",
        scope: [
            "phone",
            "email",
            "profile",
            "openid",
            "aws.cognito.signin.user.admin",
        ],
        redirectSignIn: redirectUrl,
        redirectSignOut: redirectUrl,
        responseType: "token", // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
});

export default (props) => (
    <Login {...props}>
        <Button
            color={"inherit"}
            onClick={() => Auth.federatedSignIn()}
            label={"Federated Sign In"}
        />
    </Login>
);
