import React , {Component} from "react";
import {
    NumberInput,
    SelectInput,
    SimpleFormIterator,
    ArrayInput,
    SelectArrayInput,
    FormDataConsumer
} from 'react-admin';
import {ConditionInput} from "./ConditionInput";

const DisplayConditions = function  (...props)
{
    return(
        <ArrayInput source="conditions" >
            <SimpleFormIterator>
                <ConditionInput />
            </SimpleFormIterator>
        </ArrayInput>
    );
}
export default DisplayConditions;