import React from 'react';
import { bbox } from '@turf/turf';

import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

/** @see https://docs.mapbox.com/help/tutorials/use-mapbox-gl-js-with-react/#getting-started */
export default class Mapbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lng: -70.9,
            lat: 42.35,
            zoom: 9
        };

        this.mapContainer = React.createRef();
    }

    componentDidMount() {
        const { geojson } = this.props;
        const { lng, lat, zoom } = this.state;

        const bounds = undefined !== geojson ? bbox(geojson) : [-180, -90, 180, 90];
        const map = new mapboxgl.Map({
            container: this.mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            bounds: bounds,
            fitBoundsOptions: {padding: 20}
        });
        
        if (undefined === geojson) {
            return;
        }

        map.on('load', () => {
            map.addSource('geojson', {
                type: 'geojson',
                data: geojson
            });

            map.addLayer({
                'id': 'geojson',
                'type': 'fill',
                'source': 'geojson', // reference the data source
                'layout': {},
                'paint': {
                    'fill-color': '#0080ff', // blue color fill
                    'fill-opacity': 0.5
                }
            });
        });
    }

    render() {
        return (
            <div>
                <div ref={this.mapContainer} className="map-container" />
            </div>
        );
    }
}
