import {
    ShowGuesser,
    FieldGuesser,
    InputGuesser,
} from "@api-platform/admin";
import {
    SelectField,
    ReferenceField,
    BooleanField,
    TextField,
    ReferenceArrayField,
    ArrayField
} from 'react-admin';
import { TabbedShowLayout, Tab } from 'react-admin'
import React from "react";
import {
    InnerRatio,
    DynamicRatio,
    FixedRatio
} from "../../../component/form/generationRules/ShowRatio";

export default props => (
    <ShowGuesser {...props}>
        <TabbedShowLayout>
            <Tab label="summary">
                <ReferenceField  label="experience" reference="experiences" source={"experience"}>
                    <TextField source="name" />
                </ReferenceField>
                <TextField source={"description"} />
                <TextField source={"model_id"}  />
                <TextField source={"entity_id"}  />
                <BooleanField source="enabled"/>
                <BooleanField source="simulate"/>
            </Tab>
            <Tab label={"provider"}>
                <ReferenceField  reference="abstract_sources" source={"source_provider"}>
                    <TextField source="description" />
                </ReferenceField>
                <ReferenceField  reference="generation_rules" source={"generation_rule_provider"}>
                    <TextField source="description" />
                </ReferenceField>
            </Tab>
            <Tab label="ratio">
                <InnerRatio {...props} />
                <DynamicRatio {...props} />
                <FixedRatio {...props} />
            </Tab>
            <Tab label="conditions">
                <FieldGuesser source={"conditions"} />
                <ReferenceArrayField source="conditions" >
                    <FieldGuesser source={"type"} />
                </ReferenceArrayField>
            </Tab>
            <Tab label="areas">
                <FieldGuesser source={"areas"} />
            </Tab>
        </TabbedShowLayout>
    </ShowGuesser>
);
