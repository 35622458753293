import React from "react";
import { HydraAdmin, ResourceGuesser } from "@api-platform/admin";
import Dashboard from "./layout/Dashboard";
import ResourceArea from "./layout/area/area";
import ResourceAreaType from './layout/area/areaType';
import ResourceGenerationRule from './layout/generation/generationRule';
import dataProvider from "./utils/dataProvider";
import mapboxgl from 'mapbox-gl';
import './assets/test.css';


import authProvider from "./utils/authProvider";
import LoginPage from "./component/LoginPage";


const entrypoint = `${process.env.REACT_APP_API_ENTRYPOINT}`;
mapboxgl.accessToken =`${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`;

export default () => (
  <HydraAdmin
      dashboard={Dashboard}
      title={'Hootside - Forge'}
      dataProvider={dataProvider}
      entrypoint={entrypoint}
      loginPage={LoginPage}
      authProvider={authProvider}
      disableTelemetry>
    {ResourceArea}
    {ResourceGenerationRule}
      <ResourceGuesser name={"abstract_sources"} />
      <ResourceGuesser name={"spot_sources"} />
      <ResourceGuesser name={"osm_sources"} />
      <ResourceGuesser name={"experiences"} />
      <ResourceGuesser name={"ratios"} />
      <ResourceGuesser name={"fixed_ratios"} />
      <ResourceGuesser name={"inner_ratios"} />
      <ResourceGuesser name={"dynamic_ratios"} />
    {/* <ResourceGuesser name={"place_ranks"} />
    <ResourceGuesser name={"weather"} />
    <ResourceGuesser name={"simulations"} />
    <ResourceGuesser name={"simulation_parts"} /> */}
    {/* <ResourceGuesser name={"temperature_conditions"} />
    <ResourceGuesser name={"time_conditions"} /> */}
  </HydraAdmin>
);
