import {
    InputGuesser,
} from "@api-platform/admin";
import React  from "react";
import {
    Edit,
    SimpleForm,
} from 'react-admin';

export default (props) => (
    <Edit {...props} >
        <SimpleForm>
            <InputGuesser source={"enabled"}/>
        </SimpleForm>
    </Edit>
);