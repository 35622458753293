import {
    EditGuesser,
    InputGuesser,
    CreateGuesser
} from "@api-platform/admin";
import React  from "react";
import {
    useEditContext,
    ArrayInput,
    SimpleFormIterator,
    Create,
    TabbedForm,
    FormTab,
    FormDataConsumer,
    ReferenceField,
    ReferenceInput,
    TextField,
    SelectInput,
    ReferenceArrayInput,
    SelectArrayInput,
    NumberInput,
    SimpleForm,
    Toolbar,
    SaveButton,
} from 'react-admin';
import ConditionArrayInput from "../../../component/form/generationRules/ConditionArrayInput";
import DisplayRatios from "../../../component/form/generationRules/CreateRatio";

/*SOURCES*/
const source_select = React.createRef();
const gen_select = React.createRef();
const source_referencefield = React.createRef();
const gen_referencefield = React.createRef();
const SelectSourceProvider = function (){
}
const SelectGenerationRulesProvider = function (){
    console.log("SelectGenerationRulesProvider")
    console.debug(source_select)
    console.debug(source_referencefield)
    console.log(source_referencefield.current.node.value)
    if(source_select.current !== null) {
        source_select.current.node.value=null;
        source_select.current.value=null;
    }
    if(source_referencefield.current !== null) {
        source_referencefield.current.node.value="";
        source_referencefield.current.value="";
    }
}


export default props =>
        <Create {...props} >
            <TabbedForm          >
                <FormTab label="summary">
                    <ReferenceInput source={"experience"} reference="experiences" perPage={null} required>
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <InputGuesser source={"description"} required/>
                    <InputGuesser source={"model_id"} required/>
                    <InputGuesser source={"entity_id"}/>
                    <InputGuesser source={"enabled"}/>
                    <InputGuesser source={"simulate"}/>
                </FormTab>
                <FormTab label="providers">
                    <ReferenceInput source={"source_provider"} reference="abstract_sources"
                                    inputRef={source_referencefield}
                                    onChange={SelectSourceProvider}
                                    perPage={null} 
                                    allowEmpty>
                        <SelectInput inputRef={source_select} optionText="description" />
                    </ReferenceInput>
                    <ReferenceInput source={"generation_rule_provider"} reference="generation_rules"
                                    onChange={SelectGenerationRulesProvider}
                                    inputRef={gen_referencefield}
                                    perPage={null} 
                                    allowEmpty>
                        <SelectInput optionText="description" inputRef={gen_select} />
                    </ReferenceInput>
                </FormTab>
                <FormTab label="ratio">
                    <DisplayRatios {...props} />
                </FormTab>
                <FormTab label="conditions">
                    <ConditionArrayInput {...props} />
                </FormTab>
                <FormTab label="areas">
                    <ReferenceArrayInput source="areas" reference="areas" perPage={null} >
                        <SelectArrayInput optionText="title"/>
                    </ReferenceArrayInput>
                </FormTab>
            </TabbedForm>
        </Create>
