import React  from "react";
import {
    NumberInput,
    SelectInput,
    SimpleFormIterator,
    ArrayInput,
    SelectArrayInput,
    FormDataConsumer
} from 'react-admin';

const WeatherTypes = [
    {id : 1, name:"Thunderstorm" },
    {id : 2, name:"Drizzle" },
    {id : 3, name:"Rain" },
    {id : 4, name:"Snow" },
    {id : 5, name:"Mist" },
    {id : 6, name:"Wind" },
    {id : 7, name:"Clouds" },
    {id : 8, name:"Clear" },
]
const ConditionTypes = [
    {id : "DayCondition", name:"DayCondition" },
    {id : "NightCondition", name:"NightCondition" },
    {id : "SunsetCondition", name:"SunsetCondition" },
    {id : "SunriseCondition", name:"SunriseCondition" },
    {id : "AreaCondition", name:"AreaCondition" },
    {id : "S2CellIdCondition", name:"S2CellIdCondition" },
    {id : "WeatherCondition", name:"WeatherCondition" },
]


export function ConditionInput(props){
    const [weatherCondition, setWeatherCondition] = React.useState(false)
    const handleChange = function(event){
        setWeatherCondition(false)
        switch (event.target.value)
        {
            case "WeatherCondition": setWeatherCondition(true);

        }
    }
    return <FormDataConsumer  {...props}  >
        {({  formData,
              scopedFormData,
              getSource,
              ...rest
          }) => {
            return (<>
                <SelectInput {...rest}
                             source={getSource("@type")}
                             choices={ConditionTypes}
                             label={"type"}
                             onChange={handleChange}
                />
                {
                    weatherCondition ?
                        <SelectArrayInput
                            label={"Weather"}
                            source={getSource("accepted_weather_types")}
                            choices={WeatherTypes}
                        />
                        : null
                }
            </>);
        }}
    </FormDataConsumer>


}