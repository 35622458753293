import {
    InputGuesser,
} from "@api-platform/admin";
import React  from "react";
import {
    Edit,
    TabbedForm,
    FormTab,
    ReferenceField,
    ReferenceInput,
    TextField,
    SelectInput,
    ReferenceArrayInput,
    SelectArrayInput,
} from 'react-admin';
import ConditionArrayInput from "../../../component/form/generationRules/ConditionArrayInput";
import {
    InnerRatio,
    DynamicRatio,
    FixedRatio
} from "../../../component/form/generationRules/ShowRatio";



export default (props) => (
    <Edit {...props} >
        <TabbedForm          >
            <FormTab label="summary">
                <ReferenceInput source={"experience"} reference="experiences" perPage={null} required>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <InputGuesser source={"description"} required/>
                <InputGuesser source={"model_id"} required/>
                <InputGuesser source={"entity_id"}/>
                <InputGuesser source={"enabled"}/>
                <InputGuesser source={"simulate"}/>
            </FormTab>
            <FormTab label="providers">
                <ReferenceField  reference="abstract_sources" source={"source_provider"}>
                    <TextField source="description" />
                </ReferenceField>
                <ReferenceField  reference="generation_rules" source={"generation_rule_provider"}>
                    <TextField source="description" />
                </ReferenceField>
            </FormTab>
            <FormTab label="ratio">
                <InnerRatio {...props} />
                <DynamicRatio {...props} />
                <FixedRatio {...props} />
            </FormTab>
            <FormTab label="conditions">
                <ConditionArrayInput {...props} />
            </FormTab>
            <FormTab label="areas">
                <ReferenceArrayInput source="areas" reference="areas" perPage={null} >
                    <SelectArrayInput optionText="title"/>
                </ReferenceArrayInput>
            </FormTab>
        </TabbedForm>
    </Edit>
);