import { ReferenceField, TextField } from 'react-admin';
import { ListGuesser, FieldGuesser } from '@api-platform/admin';

export default props => (
    <ListGuesser {...props}>
        {/* <ReferenceField source={"type"} reference={"area_types"}>
            <TextField source={"name"} />
        </ReferenceField> */}
        <FieldGuesser source={"title"} />
        <FieldGuesser source={"created_at"} />
        {/* <FieldGuesser source={"created_by"} /> */}
        <FieldGuesser source={"is_weather_enabled"} />
    </ListGuesser>
);