import FieldGuesser from "@api-platform/admin/lib/FieldGuesser";
import {
        Datagrid,
        List,
        ReferenceField,
        EditButton,
        DeleteButton,
} from "react-admin";
import React from "react";
import EditInList from "./EditInList";

const PostEdit = (props) => <EditInList {...props} title=" " />;
export default (props) => (
    <List
        {...props}
    >
            <Datagrid expand={<PostEdit />}>
                    <FieldGuesser source={"description"} />
                    <ReferenceField source={"experience"} reference="experiences" required>
                            <FieldGuesser source="name" />
                    </ReferenceField>
                    <FieldGuesser source={"simulate"} />
                    <FieldGuesser source={"enabled"} />
                    <FieldGuesser source={"version"} />
                    <EditButton/>
                    <DeleteButton/>

            </Datagrid>
    </List>
);
